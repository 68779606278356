import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Huetopia from "./pages/Huetopia";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import "./App.css";
import logo from "./img/logo.png";
import facebookIcon from "./img/icons/facebook.png";
import instagramIcon from "./img/icons/instagram.png";
import xIcon from "./img/icons/x.png";
import discordIcon from "./img/icons/discord.png";
import redditIcon from "./img/icons/reddit.png";

const NavLink = ({ to, icon, children }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <li>
      <Link
        to={to}
        className="flex items-center justify-center w-full px-3 py-3 text-black-500 transition duration-300"
      >
        {isMobile ? <FontAwesomeIcon icon={icon} /> : children}
      </Link>
    </li>
  );
};

function App() {
  return (
    <Router>
      <div className="min-h-screen bg-white flex flex-col">
        <nav className="bg-white p-4 h-16" style={{ "--nav-height": "4rem" }}>
          <div className="container mx-auto flex justify-between items-center h-full px-4">
            <Link to="/" className="flex items-center">
              <img src={logo} alt="Logo" className="h-7 w-auto mr-2" />
              <span className="text-xl font-bold font-dot-gothic-16 sm:inline ml-2">
                Pixel Combine
              </span>
            </Link>
            <ul className="flex space-x-4 ml-auto">
              <NavLink
                to="/about"
                icon={faInfoCircle}
                className="font-bold font-dot-gothic-16 flex justify-center items-center"
              >
                <span className="font-bold font-dot-gothic-16">About</span>
              </NavLink>
            </ul>
          </div>
        </nav>

        <main className="flex-1">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/huetopia" element={<Huetopia />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms" element={<Terms />} />
          </Routes>
        </main>

        <footer className="bg-white text-white h-auto py-8 md:py-6 flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 items-center justify-between px-6">
          <div className="flex items-center space-x-4">
            <a
              href="https://discord.gg/S4ufwKvE"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={discordIcon} alt="Discord" className="h-10 w-10" />
            </a>
            <a
              href="https://www.reddit.com/r/Huetopia/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={redditIcon} alt="Reddit" className="h-10 w-10" />
            </a>
            <a
              href="https://www.instagram.com/pixelcombine/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={instagramIcon} alt="Instagram" className="h-10 w-10" />
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=61570610055372"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={facebookIcon} alt="Facebook" className="h-10 w-10" />
            </a>
            <a
              href="https://x.com/pixelcombine"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={xIcon} alt="X" className="h-10 w-10" />
            </a>
          </div>
          <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4 items-center">
            <a
              href="/privacy"
              className="text-black hover:underline flex justify-center items-center"
            >
              Privacy Policy
            </a>
            <a
              href="/terms"
              className="text-black hover:underline flex justify-center items-center"
            >
              Terms of Use
            </a>
            {/* <a href="/contact" className="hover:underline">
              Contact
            </a> */}
            <p className="text-black">
              &copy; {new Date().getFullYear()} Pixel Combine
            </p>
          </div>
        </footer>
      </div>
    </Router>
  );
}

export default App;
