import React, { useRef, useEffect } from "react";
import THREE from "../utils/Three.js"

const ThreeScene = ({ geometry }) => {
  const mountRef = useRef(null);
  const meshRef = useRef(null);

  useEffect(() => {
    const currentMount = mountRef.current;

    // Scene
    const scene = new THREE.Scene();
    scene.background = new THREE.Color(0xffffff);

    // [Perspective Camera]
    // const camera = new THREE.PerspectiveCamera(
    //   75,
    //   currentMount.clientWidth / currentMount.clientHeight,
    //   0.1,
    //   1000
    // );

    // [Orthographic Camera]
    let aspect = currentMount.clientWidth / currentMount.clientHeight;
    let d = 2000 / currentMount.clientWidth; // Controls the size of the view volume

    const camera = new THREE.OrthographicCamera(
      -d * aspect, // left
      d * aspect, // right
      d, // top
      -d, // bottom
      0.1, // near
      50 // far
    );
    camera.position.z = 5;

    // Renderer
    const renderer = new THREE.WebGLRenderer();
    renderer.setSize(currentMount.clientWidth, currentMount.clientHeight);
    currentMount.appendChild(renderer.domElement);

    // Initial Geometry and Material
    const material = new THREE.MeshBasicMaterial({
      color: geometry.color,
      wireframe: true,
    });
    const mesh = new THREE.Mesh(geometry.geometry, material);
    meshRef.current = mesh;
    scene.add(mesh);

    // Animation
    let previousTime = 0;

    const animate = (time) => {
      requestAnimationFrame(animate);

      const deltaTime = time - previousTime;
      previousTime = time;

      mesh.rotation.x += 0.0001 * deltaTime;
      mesh.rotation.y += 0.0001 * deltaTime;

      renderer.render(scene, camera);
    };
    animate(0);

    // Handle Window Resize
    const handleResize = () => {
      // [Perspective Camera]
      // camera.aspect = currentMount.clientWidth / currentMount.clientHeight;
      // camera.updateProjectionMatrix();
      // renderer.setSize(currentMount.clientWidth, currentMount.clientHeight);

      // [Orthographic Camera]
      // Update the aspect ratio
      aspect = currentMount.clientWidth / currentMount.clientHeight;
      d = 2000 / currentMount.clientWidth;

      // Update camera properties
      camera.left = -d * aspect;
      camera.right = d * aspect;
      camera.top = d;
      camera.bottom = -d;
      camera.updateProjectionMatrix(); // Important to call this!

      // Update renderer size
      renderer.setSize(currentMount.clientWidth, currentMount.clientHeight);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
      currentMount.removeChild(renderer.domElement);
    };
  }, []);

  useEffect(() => {
    if (meshRef.current) {
      meshRef.current.geometry.dispose();
      meshRef.current.geometry = geometry.geometry;
      meshRef.current.material.color.set(geometry.color);
    }
  }, [geometry]);

  return <div ref={mountRef} style={{ width: "100%", height: "100%" }} />;
};

export default ThreeScene;
